import { Controller } from "@hotwired/stimulus"
import * as L from 'leaflet';
import { get } from '@rails/request.js'
// import { GalleryThumbnails } from "lucide";

// Connects to data-controller="leaflet"
export default class extends Controller {
  static targets = ["map", "account", "group"]
  static values = {
    blueIconUrl: String,
    orangeIconUrl: String,
    shadowUrl: String
  }

  visibleAccounts = new Set();
  selectedAccounts = new Set();
  selectedGroups = new Set();

  mapBounds
  geoJSONLayer
  orangeIcon = new L.Icon({iconUrl: this.orangeIconUrlValue, shadowUrl: this.shadowUrlValue});
  blueIcon = new L.Icon({iconUrl: this.blueIconUrlValue, shadowUrl: this.shadowUrlValue});

  mapTargetConnected(element){
    const map = L.map(element, {preferCanvas: true}).setView([35.955615358406504, -80.00355095602737], 13);
    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 13,
      minZoom: 4,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    }).addTo(map);
    map.on("moveend", (event) => {
      this.visibleAccounts.clear()
      this.fetchLocationData(map.getBounds())
    })
    map.on('zoomend', (event) => {
      if (map.getZoom() > 8) {
        if(!map.hasLayer(this.geoJSONLayer)){
          map.addLayer(this.geoJSONLayer);
        }
      } else {
        if(map.hasLayer(this.geoJSONLayer)){
          map.removeLayer(this.geoJSONLayer);
        }
      }
    });
    L.Icon.Default.prototype.options.iconUrl = `../../../../${this.blueIconUrlValue}`
    L.Icon.Default.prototype.options.shadowUrl = `../../../../${this.shadowUrlValue}`
    this.pullRecords(map)
  }

  accountSelect(event){
    const matchingLayers = this.geoJSONLayer.getLayers().filter(function(layer) {
      return layer.feature.properties.account_id == event.target.dataset.id;
    });

    this.toggleSelected(event.target, 'account')
    this.setLayerIcons(matchingLayers, event.currentTarget.hasAttribute('selected'))

    document.querySelector("#map_groups").src = this.displayUrl('groups')
  }

  groupSelect(event){
    const matchingLayers = this.geoJSONLayer.getLayers().filter(function(layer) {
      return layer.feature.properties.product_map_codes.includes(event.currentTarget.dataset.mapCode)
    });

    this.toggleSelected(event.currentTarget, 'group')
    this.setLayerIcons(matchingLayers, event.currentTarget.hasAttribute('selected'))

    document.querySelector("#map_accounts").src = this.displayUrl('accounts')
  }

  setLayerIcons(matchingLayers, change){
    const orangeIcon = this.orangeIcon
    const blueIcon = this.blueIcon
    if(change){
      matchingLayers.forEach(function(layer) {
          layer.setIcon(orangeIcon)
      });
    }else{
      matchingLayers.forEach(function(layer) {
        layer.setIcon(blueIcon)
    });
    }
  }

  accountTargetConnected(element){
    if(this.selectedAccounts.has(element.dataset.id)){
      this.toggleSelected(element, 'account')
    }
  }

  groupTargetConnected(element){
    if(this.selectedGroups.has(element.dataset.id)){
      this.toggleSelected(element, 'group')
    }
  }

  getShownAccounts(mapBounds){
    let accounts = this.visibleAccounts
    this.geoJSONLayer.eachLayer(function(layer) {
      if (mapBounds.contains(layer.getLatLng())) {
        accounts.add(layer.feature.properties.account_id)
      }
    })
    return accounts
  }


  toggleSelected(element, kind){
    element.toggleAttribute('selected')
    element.classList.toggle("bg-orange-100")
    element.classList.toggle("text-orange-500")
    if(element.hasAttribute('selected')){
      if(kind == 'account'){
        this.selectedAccounts.add(element.dataset.id)
      }
      if(kind == 'group'){
        this.selectedGroups.add(element.dataset.id)
      }
    }else{
      if(kind == 'account'){
        this.selectedAccounts.delete(element.dataset.id)
      }
      if(kind == 'group'){
        this.selectedGroups.delete(element.dataset.id)
      }
    }
  }

  fetchLocationData(mapBounds){
    this.getShownAccounts(mapBounds)
    document.querySelector("#map_accounts").src = this.displayUrl('accounts')
    document.querySelector("#map_groups").src = this.displayUrl('groups')
  }

  displayUrl(name){
    const accounts = this.visibleAccounts
    let params = new URLSearchParams();
    Array.from(accounts).forEach(value => {
      params.append('account_id[]', value);
    });
    Array.from(this.selectedAccounts).forEach(value => {
      params.append('selected_accounts[]', value);
    });
    Array.from(this.selectedGroups).forEach(value => {
      params.append('selected_groups[]', value);
    });
    return `map/location/${name}?`+params.toString()
  }

  onEachFeature(feature, layer) {
    if (feature.properties && feature.properties.popupContent) {
        layer.bindPopup(feature.properties.popupContent);
      }
  }

  async pullRecords(map){
    let locations = []
    const blueIcon = this.blueIcon
    const response = await get("map/location/data", {responseKind: "json", query: {}})
    if (response.ok){
      locations = await response.json
      this.geoJSONLayer = L.geoJSON(locations, {onEachFeature: this.onEachFeature}).addTo(map);
    }
  }

}
