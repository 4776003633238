import { Controller } from "@hotwired/stimulus"
import {tns} from 'tiny-slider';

// Connects to data-controller="tiny-slider"
export default class extends Controller {
  static targets = ["slider"]

  connect() {
  }

  sliderTargetConnected(element){
    const slider = tns({
      container: element,
      items: 1,
      slideBy: 1,
      controls: true,
      nav: false,
      navPosition: "top",
      autoHeight: true
    });
  }

}
