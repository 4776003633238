import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="binding"
export default class extends Controller {
  static targets = ["inputField", "priceColumn"]

  updateValues(event){
    this.inputFieldTargets.forEach(element => {
      if(element.dataset.bindingId == event.target.dataset.bindingId){
        if(event.target != element){
          element.value = event.target.value
        }
      }
    });
    this.updateTotalPrice(event.target.dataset.priceColumn)
  }

  updateTotalPrice(priceColumn){
    const columns = document.querySelectorAll(`[data-price-column='${priceColumn}']`)
    const total = document.querySelector(`[data-price-total='${priceColumn}']`)
    if (total) {
      let sum = 0
      columns.forEach(element => {
        sum += Number(element.value)
      })
      total.innerHTML = `$${sum}`
    }
  }


}
