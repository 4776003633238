import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

// Connects to data-controller="documents"
export default class extends Controller {

  static targets = ["file", "folder"]

  connect() {
    console.log('connected to documents')
  }

  changeFolder(event) {
    const searchValue = document.querySelector('#document-search').value.toLowerCase()
    this.showHideDocuments(event.target.dataset['folder'], searchValue)
  }

  async checkedProposals(event){
    const url = event.target.dataset.url
    const checked = event.target.checked
    console.log(checked)
    if(checked){
      if(url){
        const response = await get(url)
        if (response.ok){
          let ids = await response.json
          this.fileTargets.forEach((file) => {
            if(ids.includes(file.id)){
              file.hidden = false
            }else{
              file.hidden = true
            }
          })
        }
      }
    }else{
      this.fileTargets.forEach((file) => {
        file.hidden = false
      })
    }
  }

  showHideDocuments(folderName, searchValue){
    this.fileTargets.forEach((file) => {
      if (folderName === "ALL"){
        this.search(file, searchValue)
      } else {
        if (file.dataset['folder'] === folderName) {
          this.search(file, searchValue)
        } else {
          file.hidden = true
        }
      }
    })
  }

  searchField(event){
    const folder = document.querySelector('.nav-link.active')
    let folderName
    if (folder){
      folderName = folder.dataset['folder']
    } else {
      folderName = 'ALL'
    }
    this.showHideDocuments(folderName, event.target.value.toLowerCase())
  }

  search(file, searchValue) {
    if (file.textContent.toLowerCase().includes(searchValue)) {
      file.hidden = false
    }else{
      file.hidden = true
    }
  }

}
