import { Controller } from "@hotwired/stimulus"


// Connects to data-controller="cover-display"
export default class extends Controller {
  static targets = [ "filter", "cover", "color", 'category', 'grade']

  categoryFilters = document.querySelectorAll(`[data-filter="category"]`)

  categoryFilter(event){
    const checked = event.target.checked
    const filter = event.target.dataset.filter
    const id = event.target.dataset.filterId
    const objects = document.querySelectorAll(`[data-${filter}~="${id}"]`)
    const onCategories = this.getOnCategories()
    if(checked){
      // Turning On
      objects.forEach(element => {
        element.hidden = false
      })
    }else{
      // Turning Off
      if(onCategories.length <= 0){
        // Last thing Turned Off
        objects.forEach(element => {
          element.hidden = true
        })
      }else{
        // At least 2 unchecked filters
        objects.forEach(element => {
          element.hidden = this.hiddenCheck(element, onCategories, filter)
        })
      }
    }
  }

  hiddenCheck(element, onCategories, filter){
    const ids = element.dataset[filter].split(' ')
    for (let i = 0; i < onCategories.length; i++) {
      if(ids.includes(onCategories[i])){
        return false
      }
    }
    return true
  }

  getOnCategories(){
    let array = []
    this.categoryFilters.forEach(element => {
      if(element.checked){
        array.push(element.dataset.filterId)
      }
    });
    return array
  }

  filter(event){
    const checked = event.target.checked
    const filter = event.target.dataset.filter
    const id = event.target.dataset.filterId
    const objects = document.querySelectorAll(`[data-${filter}="${id}"]`)
    if (objects.length > 0 ){
      objects.forEach(element => {
        element.hidden = !checked
      });
    }
  }

  top25(event){
    const checked = event.target.checked
    const filter = event.target.dataset.filter
    const id = event.target.dataset.filterId
    const objects = document.querySelectorAll(`[data-${filter}="${id}"]`)
    if (objects.length > 0 ){
      objects.forEach(element => {
        element.hidden = checked
      });
    }
  }
}
