import { Controller } from "@hotwired/stimulus"

import Uppy from '@uppy/core'
import { post } from '@rails/request.js'
// import XHRUpload from '@uppy/xhr-upload'
import Dashboard from '@uppy/dashboard'
// import DragDrop from '@uppy/drag-drop'
// import Informer from '@uppy/informer'
import AwsS3 from '@uppy/aws-s3'
// import American from '@uppy/locales/lib/en_US'

// Connects to data-controller="dropzone"
export default class extends Controller {

  connect() {
    const uppyObject = document.querySelector("[data-form-type='uppy']")
    if (uppyObject) {
      this.initUppy(uppyObject)
    };
  }

  initUppy(uppy_node){

    const uppy = new Uppy({
      id: uppy_node.dataset['uppyInstance'],
      autoProceed: true,
      allowMultipleUploadBatches: true,
      restrictions: {
        maxFileSize: uppy_node.dataset['maxFileSize']*1024*1024,
        maxNumberOfFiles: null,
        minNumberOfFiles: null,
        allowedFileTypes: uppy_node.dataset['allowedFiles'].split(',')
      }
    })
    uppy.use(Dashboard, {
      target: uppy_node,
      inline: true,
      replaceTargetContent: true,
      showProgressDetails: true,
      width: 1200,
      height: 200,
    })

    uppy.use(AwsS3, {
      companionUrl: uppy_node.dataset['presignUrl'],
    })

    uppy.on('upload-success', function (file, data) {
      // construct uploaded file data in the format that Shrine expects
      console.log("File was successfully uploaded")
      const uploadedFileData = JSON.stringify({
        id: file.meta['key'].match(/^cache\/(.+)/)[1], // object key without prefix
        storage: 'cache',
        metadata: {
          size: file.size,
          filename: file.name,
          mime_type: file.type,
        }
      })
      console.log(uploadedFileData)
      post(
          uppy_node.dataset['postUrl'],
          {
            responseKind: "turbo-stream",
            body: JSON.stringify({
              [uppy_node.dataset['railsModel']]: {
                [uppy_node.dataset['imageAttribute']]: uploadedFileData
              }
            })
          }
        )
      console.log(uppy_node.dataset['postUrl'], uppy_node.dataset['railsModel'], uppy_node.dataset['imageAttribute'])
    })

  }

}

