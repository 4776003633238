import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="tippy"
export default class extends Controller {
  static targets = ["color"]

  connect() {
  }

  colorTargetConnected(element){
    tippy(element, {
      content: "I'm a Tippy tooltip!",
    });
  }

}
