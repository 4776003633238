import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select";

// Connects to data-controller="select"
export default class extends Controller {
  static targets = ["inventoryItemSelect", "standardSelect", "select", "emails", "accountSelect", "productGroupCover"]

  connect() {
  }


  emailsTargetConnected(element){
    if (element.classList.contains('tomselected')) return;
    new TomSelect(element,{
      create: true,
      plugins: {
        remove_button:{
          title:'Remove this item',
        }
      },
      valueField: 'email',
      labelField: 'email',
      searchField: ['email'],
      createFilter: element.dataset.regex,
      onItemAdd(value, $item){
        this.setTextboxValue('')
      }
    });
  }

  standardSelectTargetConnected(element){
    if (element.classList.contains('tomselected')) return;
    new TomSelect(element,{
      valueField: 'id',
      labelField: 'name',
      maxOptions: null,
      searchField: ['name','id'],
      sortField:[{field:'name'}],
    });
  }

  accountSelectTargetConnected(element){
    if (element.classList.contains('tomselected')) return;
    new TomSelect(element,{
      lockOptgroupOrder: true,
      valueField: 'id',
      labelField: 'name',
      maxOptions: null,
      optgroupField: 'status',
      optgroups: [
        {value: 'managed', label: "Managed"},
        {value: 'unmanaged', label: "Unmanaged"}
      ],
      searchField: ['name','id'],
      sortField:[{field:'name'}],
      onInitialize:function(){
        this.popper = Popper.createPopper(this.control,this.dropdown);
      },
      onDropdownOpen:function(){
        this.popper.update();
      },
      render: {
        optgroup_header: function(data, escape) {
          return '<h3 class="text-xl font-bold underline optgroup-header sticky top-0 bg-white p-2">' + escape(data.label) +'</h3>';
        }
      }
    });
  }

  bottomSelectTargetConnected(element){
    if (element.classList.contains('tomselected')) return;
    new TomSelect(element,{
      onInitialize:function(){
        this.popper = Popper.createPopper(this.control,this.dropdown);
      },
      onDropdownOpen:function(){
        this.popper.update();
      }
    });
  }

  inventoryItemSelectTargetConnected(element){
    new TomSelect(element,{
      valueField: 'id',
      labelField: 'name',
      optgroupField: 'class',
      maxOptions: null,
      searchField: ['name','display','id'],
      sortField:[{field:'name'}],
      render: {
        option: function(data, escape) {
          if(data.display){
            return JSON.parse(data.display);
          }
        },
        item: function(data, escape) {
          if(data.display){
            return JSON.parse(data.display);
          }
        },
      },
    });
  }

  productGroupCoverTargetConnected(element) {
    if (element.classList.contains('tomselected')) return;
    let url = element.dataset.url;
    const dropdownId = element.id;
    console.log("CONNECTED PRODUCT GROUP COVER")
    new TomSelect(element,{
        plugins: {
          remove_button:{
            title:'Remove this item',
          }
        },
        valueField: 'id',
        labelField: 'name',
        searchField: ['name','description'],
        // fetch remote data
        load: function(query, callback) {
          var self = this;
          if( self.loading > 1 ){
            callback();
            return;
          }
          let queryUrl = url + "?q=" + encodeURIComponent(query);
          fetch(queryUrl)
            .then(response => response.json())
            .then(json => {
              console.log(json);
              callback(json);
            }).catch(()=>{
              callback();
            });
        },
        onLoad(){
          let dropdown = document.querySelector(`#${dropdownId}-ts-dropdown`)
          dropdown.classList.add("flex")
          dropdown.classList.add("flex-wrap")
        },
        // custom rendering function for options
        render: {
          option: function(item, escape) {
            return `<div class="box p-5">
            <div class="flex gap-2">
                <div class="w-40 grid place-content-center">
                    <img class="" src="${item.image_url}" />
                </div>
                <div class="grid content-center">
                    <div class="font-bold text-xl">${escape(item.name)}</div>
                    <div class="font-medium">${escape(item.description)}</div>
                    <div class="text-orange-500 font-medium">${escape(item.cover)}</div>
                </div>
            </div>
        </div>`;
          },
          item: function(item, escape) {
            return `<div class="box p-5">
            <div>
            <div class="flex gap-2">
                <div class="w-40 grid place-content-center">
                    <img class="" src="${item.image_url}" />
                </div>
                <div class="grid content-center">
                    <div class="font-bold text-xl">${escape(item.name)}</div>
                    <div class="font-medium">${escape(item.description)}</div>
                    <div class="text-orange-500 font-medium">${escape(item.cover)}</div>
                </div>
            </div><div class="">${escape(item.color)}</div></div>
        </div>`;
          },
        },
      });
    }


}
