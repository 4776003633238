import { Controller } from "@hotwired/stimulus"
// import { Calendar } from '@fullcalendar/core';
// import dayGridPlugin from '@fullcalendar/daygrid';
// import timeGridPlugin from '@fullcalendar/timegrid';
// import listPlugin from '@fullcalendar/list';
// import interactionPlugin from '@fullcalendar/interaction'; // for selectable
// import momentTimezonePlugin from '@fullcalendar/moment-timezone'
// import { patch } from '@rails/request.js'

// Connects to data-controller="calendar"
export default class extends Controller {
  // static targets = [ "refresh", "unselect", "eventSource"]
  // calendar

  // initialize(){
  //   let calendarEl = document.getElementById('calendar');
  //   this.calendar = new Calendar(calendarEl, {
  //     timeZone: document.querySelector('#timezone').value,
  //     select: function(info) {
  //       const element = document.querySelector('#new_event')
  //       let url = new URL(element.href)
  //       const searchParams = url.searchParams
  //       searchParams.set('start', info.startStr)
  //       searchParams.set('end', info.endStr)
  //       searchParams.set('allDay', info.allDay)
  //       searchParams.set('timezone', info.view.calendar.currentData.dateEnv.timeZone)
  //       element.href = url.toString()
  //       element.click()
  //     },
  //     eventClick: function(info){
  //       const element = document.querySelector('#show_event')
  //       element.href = '/calendar_events/' + info.event.id
  //       let url = new URL(element.href)
  //       const searchParams = url.searchParams
  //       searchParams.set('timezone', info.view.calendar.currentData.dateEnv.timeZone)
  //       element.href = url.toString()
  //       element.click()
  //     },
  //     eventDrop: function(info) {
  //       patch("/calendar_events/" + info.event.id, {responseKind: "turbo-stream", body: {
  //         start_datetime: info.event.start,
  //         end_datetime: info.event.end,
  //         all_day: info.event.allDay
  //       }})
  //     },
  //     eventResize: function(info) {
  //       patch("/calendar_events/" + info.event.id, {responseKind: "turbo-stream", body: {
  //         start_datetime: info.event.start,
  //         end_datetime: info.event.end,
  //         all_day: info.event.allDay
  //       }})
  //     },
  //     buttonText: {
  //       timeGridDay: 'Day',
  //       listDay: 'Day'
  //     },
  //     plugins: [ dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin, momentTimezonePlugin],
  //     initialView: 'dayGridMonth',
  //     selectable: true,
  //     slotDuration: '00:15:00',
  //     selectMirror: true,
  //     unselectAuto: false,
  //     nowIndicator: true,
  //     headerToolbar: {
  //       left: 'prev,next today',
  //       center: 'title',
  //       right: 'dayGridMonth,timeGridWeek,listWeek,timeGridDay,listDay'
  //     },
  //     eventSources: [
  //       {
  //         url: '/get_events'
  //       }
  //     ]
  //   });
  //   this.calendar.render();
  //   this.getEvents();
  // }

  // allUsers(event){
  //   this.eventSourceTargets.forEach((source) => {
  //     source.checked = event.target.checked
  //   })
  //   this.getEvents();
  // }

  // getEvents(){
  //   this.eventSourceTargets.forEach((source) => {
  //     let eventSrc = {
  //       id: source.dataset.calendarIdParam,
  //       url: source.dataset.calendarUrlParam
  //     }
  //     if (this.calendar.getEventSourceById( source.dataset.calendarIdParam )){
  //       if (source.checked){
  //       }else{
  //         this.calendar.getEventSourceById( source.dataset.calendarIdParam ).remove()
  //       }
  //     }else{
  //       if (source.checked){
  //         this.calendar.addEventSource( eventSrc )
  //       }
  //     }
  //   })
  // }

  // changeTimezone(event){
  //   this.calendar.setOption('timeZone', event.target.value);
  //   console.log(event.target.value)
  // }

  // refreshTargetConnected(element) {
  //   this.calendar.unselect()
  //   this.calendar.refetchEvents()
  //   element.remove()
  // }

  // unselectTargetConnected(element) {
  //   this.unselect()
  //   element.remove()
  // }

  // unselect(){
  //   console.log("unselected")
  //   this.calendar.unselect()
  // }

  // changeUser(event){
  //   if (event.target.checked){
  //     const eventSrc = {
  //       id: event.params.id,
  //       url: event.params.url
  //     }
  //     this.calendar.addEventSource( eventSrc )
  //   }else{
  //     this.calendar.getEventSourceById( event.params.id ).remove()
  //   }
  // }

  // connect() {
  // }


}
