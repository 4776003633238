import { Controller } from "@hotwired/stimulus"
import { patch } from "@rails/request.js"
import Sortable from "sortablejs"


// Connects to data-controller="sortable"
export default class extends Controller {
  static targets = [ "sortableGroup"]
  static values = {url: String}

  sortableItems = {}
  counter = 0

  sortableGroupTargetConnected(element){
    this.counter += 1
    element.dataset["sortableNumber"] = this.counter
    const label = element.dataset["sortableLabel"]
    console.log(label)
    this.sortableItems[this.counter] = Sortable.create(element, {
      animation: 150,
      group: 'group',
      ghostClass: "bg-indigo-200",
      onEnd: this.onEnd.bind(this),
      draggable: '.draggable',
      onStart: this.onStart.bind(this),
      handle: `[data-sortable-handle='${label}']`
    })
  }

  sortableGroupTargetDisconnected(element){
    this.sortableItems[element.dataset["sortableNumber"]].destroy()
  }

  onStart(event){
    const { newIndex, item } = event
    console.log(item)
    console.log(event)
    const label = item.dataset["sortableLabel"]
    const selector = `[data-${label}-hide='true']`
    console.log(label, selector)
    this.toggleElements(selector, true)
  }

  onEnd(event){
    const { newIndex, item } = event
    const label = item.dataset["sortableLabel"]
    const id = item.dataset["sortableId"]
    const groupLabel = item.parentElement.dataset["sortableGroup"]
    const groupId = item.parentElement.dataset["sortableGroupId"]
    const selector = `[data-${label}-hide='true']`
    let url = this.urlValue
    if(label && id){ url = url + `/${label}/${id}`}
    if(groupLabel && groupId){ url = url + `/${groupLabel}/${groupId}`}
    patch(url, {
      body: JSON.stringify({position: (newIndex + 1) })
    })
    console.log(label, selector, id, url)
    this.toggleElements(selector, false)
  }

  toggleElements(selector, hide){
    const records = document.querySelectorAll(selector)
    if(records.length > 0){
      records.forEach(element => {
        element.hidden = hide
      })
    }
  }




}
