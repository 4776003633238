import { Controller } from "@hotwired/stimulus";
import triggerTomSelect from '../templatejs/tom-select'

export default class extends Controller {
  static targets = ["template", "body"];

  counter = 0

  connect(){
    this.counter = this.bodyTarget.querySelectorAll('.position_counter').length
  }

  remove(event) {
    event.preventDefault();
    if (confirm("Are you sure you want to do that?")){
      event.target.closest('.dynamic-item').querySelector('.destroy').value = true
      event.target.closest('.dynamic-item').style.display = "none";


      if(event.params.targets){
        document.querySelectorAll(event.params.targets).forEach(el => {
          el.style.display = "none";
        });
      }

    }
  }

  add(event) {
    event.preventDefault();
    this.bodyTarget.insertAdjacentHTML(
      "beforeEnd",
      this.templateTarget.innerHTML.replace(
        /__CHILD_INDEX__/g,
        new Date().getTime().toString()
      )
    );
    this.trigger_tom_select(this.bodyTarget)
  }

  trigger_tom_select(object){
    object.querySelectorAll('.new').forEach((ele)=>{
      triggerTomSelect(ele)
      ele.classList.remove('new')
      let position = ele.querySelector('.position_counter')
      if(position && position.value == ''){
        position.value = (this.counter + 1)
      }
    })
    this.counter++
  }
}
